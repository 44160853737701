import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { tablet } from "../../../../../constants/screenSizes"
import { skyBlue, white } from "../../../../../constants/colors"
import { Icon } from "semantic-ui-react"
import { colors } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  flex: 1;
  font-size: 1.1em;

  @media (max-width: ${tablet}px) {
    font-size: 0.9em;
  }
`
const CheckIcon = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
`
const ListItem = styled.div`
  display: flex;
  align-items: center;
`
const ListItemLabel = styled.div`
  margin-left: 20px;
  @media (max-width: ${tablet}px) {
    font-size: 0.9em;
  }
`

interface Props {
  listItems: string[]
  className?: string
  iconColor?: string
}

const LPFeatureTicks: FC<Props> = ({ listItems, className, iconColor = colors.primaryLight }) => {
  return (
    <Container className={className}>
      {listItems.map(text => (
        <ListItem key={text}>
          <CheckIcon name="check" size="small" color={iconColor} />
          <ListItemLabel color={white}>{text}</ListItemLabel>
        </ListItem>
      ))}
    </Container>
  )
}

export default LPFeatureTicks
